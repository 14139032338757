<template>
  <div class="container pb-6" id="pulse-check-content">
    <div class="mt-4" v-if="loading">
      <loader></loader>
    </div>
    <div v-else class="pulse-checks mb-5 pt-1 row no-margin">
      <div class="col-sm-12 col-lg-8 offset-lg-2 mt-4">
        <h4 class="title" v-html="$options.filters.replaceTags(data.title)">
          <small v-if="data.isReadOnly && data.dateFinished"
            >{{ 'general.submittedOn' | translate }} {{ data.dateFinished }}</small
          >
        </h4>
        <div class="warning-message" v-if="data.warningMessage">
          {{ $t('pulseCheck.warning') }}: {{ data.warningMessage }}
        </div>
      </div>
      <div class="col-sm-12 col-lg-8 offset-lg-2">
        <ul class="list-group mt-3" v-if="items">
          <li class="list-group-item" v-for="item in items" :key="item.id">
            <div class="row" v-if="item.type === 1">
              <div class="col-sm-7 col-md-8 col-lg-8 text-left">
                <h6
                  class="question-text mt-2"
                  :style="{ color: style.pulseCheckTitleColor }"
                  v-html="$options.filters.replaceTags(item.question)"
                ></h6>
              </div>
              <div class="col-sm-5 col-md-4 col-lg-4 rating-column">
                <el-rate
                  v-model="item.rating"
                  :colors="[style.pulseCheckStarColor, style.pulseCheckStarColor, style.pulseCheckStarColor]"
                  required
                  :disabled="data.isReadOnly"
                ></el-rate>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-sm-12">
                <p
                  class="question-text"
                  :style="{ color: style.pulseCheckTitleColor }"
                  v-html="$options.filters.replaceTags(item.question)"
                ></p>
                <textarea
                  class="form-control"
                  v-model="item.comment"
                  :placeholder="$t('pulseCheck.typeYourAnswer')"
                  :disabled="data.isReadOnly"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-8 offset-lg-2 mt-4" v-if="!loading && data">
        <div class="d-flex justify-content-end">
          <secondary-button
            class="mr-3 d-flex align-items-center"
            @click="cancel"
            :disabled="loading"
            v-if="!data.isReadOnly"
          >
            <i class="material-icons float-left mr-1">cancel</i>
            <span>{{ 'general.cancel' | translate }}</span>
          </secondary-button>
          <primary-button
            class="d-flex align-items-center"
            @click="submit"
            :disabled="loading || hasEmptyRatings"
            v-if="!data.isReadOnly"
          >
            <i class="material-icons float-left mr-1">send</i>
            {{ 'general.send' | translate }}
          </primary-button>
          <primary-button
            class="d-flex align-items-center"
            @click="backToDashboard"
            :disabled="loading"
            v-if="data.isReadOnly"
          >
            <i class="material-icons float-left mr-1">arrow_back</i>
            {{ 'general.returnToDashboard' | translate }}
          </primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import some from 'lodash/some';
import planhatMixin from '../../mixins/planhat';
import { Rate } from 'element-ui';

export default {
  components: {
    elRate: Rate
  },
  mixins: [planhatMixin],
  created() {
    this.$http.get(`dashboard/pulsechecks/${this.$route.params.overview}/overview/${this.$route.params.id}`).then(
      (response) => {
        if (response.data.isReadOnly) {
          response.data.dateFinished = new Date(response.data.dateFinished).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }); // moment(response.data.dateFinished).format("DD/MM/YYYY");
        }
        this.data = response.data;
        this.loading = false;
      },
      (err) => {
        this.$notify({
          type: 'error',
          message: this.$t('notifications.error'),
          position: 'bottom-right'
        });
        this.loading = false;
        // go back by one record, the same as history.back()
        this.$router.go(-1);
      }
    );
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('settings', ['style']),
    hasEmptyRatings() {
      return some(
        this.items.filter((i) => i.type === 1),
        { rating: 0 }
      );
    },
    items() {
      return this.data.questions.map((i) => {
        i.rating = Math.round(i.rating * 2) / 2;
        return i;
      });
    }
  },
  data() {
    return {
      data: {
        questions: []
      },
      loading: true
    };
  },
  methods: {
    backToDashboard() {
      this.$router.push({ name: 'dashboard' });
    },
    cancel() {
      this.$router.go(-1);
    },
    submit() {
      let hasUnansweredQuestions;
      let data = {
        OpenQuestions: [],
        RatingQuestions: [],
        sendToManager: true
      };

      this.data.questions.forEach((item) => {
        if (item.type === 1) {
          // rating question
          data.RatingQuestions.push({
            id: parseInt(item.id),
            rating: parseInt(item.rating)
          });
        } else if (item.type === 2) {
          // open question
          data.OpenQuestions.push({
            id: parseInt(item.id),
            comment: item.comment ? item.comment : ''
          });
          if (!item.comment || item.comment === '') {
            hasUnansweredQuestions = true;
          }
        }
      });

      const update = () => {
        this.$http
          .post(`dashboard/pulsechecks/${this.$route.params.overview}/overview/${this.$route.params.id}/answers`, data)
          .then(
            () => {
              this.$notify({
                type: 'success',
                message: this.$t('notifications.answersSaved'),
                position: 'bottom-right'
              });
              this.$router.push({ name: 'dashboard' });
              //Planhat pulse check completed, description is optional
              this.track('tal_pulse_check_completed');
            },
            (err) => {
              if (err !== 'demo') {
                this.$notify({
                  type: 'error',
                  message: this.$t('notifications.error'),
                  position: 'bottom-right'
                });
              }
              this.loading = false;
            }
          );
      };

      if (hasUnansweredQuestions) {
        this.$confirm(this.$t('pulseCheck.hasUnansweredQuestions'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.loading = true;
          update();
        });
      } else {
        this.loading = true;
        update();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.warning-message {
  font-style: italic;
}
h4.title small {
  font-size: small;
  display: none;
  @media (min-width: 772px) {
    float: right;
    display: inline-block;
  }
}

.rating-column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 38px;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
}

i.material-icons {
  font-size: var(--title-size);
}

.pulse-checks {
  .list-group-item {
    .question-text,
    .form-control {
      font-size: var(--text-size);
    }
    .el-rate:focus {
      outline: none;
    }
  }
}

.title {
  font-size: var(--title-size);
}
</style>
